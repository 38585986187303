'use client'; // Error boundaries must be Client Components

import { redirect } from 'next/navigation';
import { useEffect } from 'react';

export const fetchCache = "force-cache";
export const dynamic = "force-static";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    console.error(error);
    redirect('/error');
  }, [error]);

  return null;
}